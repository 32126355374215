<template>
    <v-flex>
        <v-container fluid>
            <v-layout fill-height row wrap justify-center align-center>
                <v-flex mx-12 lg-12 md-12>
                    <v-alert :dismissible="false" class="green white--text" dark border="left" >
                            <span> Data</span>
                    </v-alert>
                </v-flex>
                <v-flex xs11 sm11>
                    {{category}}
                    <v-card>
                        <v-card-title>
                            <v-btn @click="category = 'spot'">Spot</v-btn>
                            <v-btn @click="category = 'futures'">Futures</v-btn>
                            <v-col cols="2">
                            <v-select :items="excahngeList" label="Exhcange" v-model="exchange"></v-select>
                            </v-col>
                            <v-col cols="2">
                            <v-select :items="category == 'spot' ? okexSpotSymbol : okexFuturesSymbol" label="Symbol" v-model="symbol" v-if="exchange == 'okex'"></v-select>
                            <v-select :items="category == 'spot' ? binanceSpotSymbol : binanceFuturesSymbol" label="Symbol" v-model="symbol" v-if="exchange == 'binance'"></v-select>
                            <v-select :items="category == 'spot' ? krakenSpotSymbol : krakenFuturesSymbol" label="Symbol" v-model="symbol" v-if="exchange == 'kraken'"></v-select>
                            </v-col>
                            <v-btn @click="getOkexData(symbol)" v-if="exchange == 'okex'">get</v-btn>
                            <v-btn @click="getBinanceData(symbol)" v-if="exchange == 'binance'">get</v-btn>
                            <v-btn @click="getKrakenData(symbol)" v-if="exchange == 'kraken'">get</v-btn>
                        </v-card-title>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-flex>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                category:"futures",
                excahngeList:["kraken","okex","binance"],
                okexSpotSymbol:["BTC-USDT","LTC-USDT","ETH-USDT","OKB-USDT"],
                okexFuturesSymbol:["BTC-USD-SWAP","BTC-USDT-SWAP","LTC-USD-SWAP","LTC-USDT-SWAP","ETH-USD-SWAP","ETH-USDT-SWAP","ETC-USD-SWAP","ETC-USDT-SWAP","XRP-USD-SWAP","XRP-USDT-SWAP","EOS-USD-SWAP","EOS-USDT-SWAP","BCH-USD-SWAP","BCH-USDT-SWAP","BSV-USD-SWAP","BSV-USDT-SWAP","TRX-USD-SWAP","TRX-USDT-SWAP"],
                binanceSpotSymbol:["BTCUSDT","ETHUSDT","LTCUSDT","TRXUSDT","BCHUSDT","BNBUSDT","EOSUSDT","XRPUSDT","MATICUSDT","BUSDUSDT","ETCUSDT","USDCUSDT","ARPAUSDT","WINUSDT"],
                binanceFuturesSymbol:["BTCUSDT","ETHUSDT","BCHUSDT"],
                krakenSpotSymbol:["XBTUSD"],
                krakenFuturesSymbol:["BTCUSDT","ETHUSDT","BCHUSDT"],
                exchange:"okex",
                symbol:"BTC-USD-SWAP",
                okexData:{},
                binanceData:{},
                krakenData:{},
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
        },
        created() {
           
        },
        methods: {
            getOkexData(symbol){
                this.okexData = {};
                this.okexData.symbol = symbol;
                
                this.getOkexInterest(symbol);
                this.getTraders(symbol);
            },
            getBinanceData(symbol){
                this.binanceData = {};
                this.binanceData.symbol = symbol;
                this.getBinanKline(symbol);
                this.getBianncePrice(symbol);
                this.getBinanceInterest(symbol);
            },
            getKrakenData(symbol){
                this.krakenData ={};
                this.krakenData.symbol = symbol;
                this.getKrakenPrice(symbol)
            },
           // 获取交易量
            getOkexCandles(symbol){
                let api = this.category == "spot" ? "https://www.okex.me/api/spot/v3/instruments/"+ symbol +"/candles?start=2019-09-01T00:00:00.000Z&end=2020-01-07T00:00:00.000Z&granularity=86400" :"https://www.okex.me/api/swap/v3/instruments/BTC-USD-SWAP/candles?start=2019-09-01T00:00:00.000Z&end=2020-01-07T00:00:00.000Z&granularity=86400";
                // let api = "https://www.okex.me/api/swap/v3/instruments/ticker"
                // let api = "https://www.okex.me/api/swap/v3/instruments/"+ symbol +"/ticker"
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    this.category == "spot" ? this.okexData.交易量 = data[0][5] :this.okexData.交易量 = data[0][6];
                })
                console.log(this.okexData)
            },
            getBinanKline(symbol){
                let timestamp = (new Date(new Date().toLocaleDateString()).getTime())+ 8 * 60 * 60 * 1000;
                let api = this.category == 'spot' ? "https://api.binance.com/api/v3/klines?symbol="+symbol+"&interval=1d" : "https://fapi.binance.com/fapi/v1/klines?symbol="+symbol+"&interval=1d";
                this.$http.get(api).then(res=>{
                    let data = res.data[res.data.length-1];
                    this.binanceData.交易量 = data[5];
                })
            },
            // 获取持仓量
            getOkexInterest(symbol){
                if(this.category == "futures"){
                    let api = "https://www.okex.me/api/swap/v3/instruments/"+ symbol +"/open_interest";
                    this.$http.get(api).then(res=>{
                    let data = res.data;
                    this.okexData.持仓量 = data.amount;
                    })
                }
            },
            getBinanceInterest(symbol){
                if(this.category == 'futures'){
                    let api = "https://fapi.binance.com/fapi/v1/openInterest?symbol="+symbol;
                    this.$http.get(api).then(res=>{
                    let data = res.data;
                    this.binanceData.持仓量 = data.openInterest;
                    })
                }
            },
            // 获取最新成交价
            getTraders(symbol){
                let api = this.category == "spot" ? "https://www.okex.me/api/spot/v3/instruments/"+ symbol +"/trades" :"https://www.okex.me/api/swap/v3/instruments/"+ symbol +"/trades";
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    this.okexData.成交价 = data[0].price;
                })
                 this.getOkexCandles(symbol);   
            },
            getBianncePrice(symbol){
                let api = this.category == "spot" ? "https://api.binance.com/api/v3/ticker/price?symbol="+symbol : "https://fapi.binance.com/fapi/v1/ticker/price?symbol="+symbol;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    this.binanceData.成交价 = data.price;
                })
                    console.log(this.binanceData)
            },
            getKrakenPrice(symbol){
                let api = this.category == 'spot' ? "https://api.kraken.com/0/public/Trades?pair="+symbol : "";
                this.$http.get(api).then(res=>{
                    let data = res.data.result;
                    this.krakenData.成交价 = data;
                    console.log(data)

                })
            }
        }
    }
</script>

<style>

</style>