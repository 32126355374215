<template>
    <div>
        <v-row class="mx-2">
            <v-col cols="auto">
                <v-btn color="teal white--text" icon small @click="changeTable()">
                    <v-icon>fas fa-arrow-left</v-icon>
                </v-btn>
                <span class="ml-5">IOB Synthetic Indices</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-btn class="ml-3" color="teal white--text" icon small @click="action('add2',null)">
                    <v-icon>fas fa-calculator</v-icon>
                </v-btn>
                <v-btn class="ml-3" color="teal white--text" icon small @click="action('add',null)">
                    <v-icon>fas fa-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mx-2">
            <v-col cols="12" v-if="searchLoading">
                <v-skeleton-loader dense type="table-row-divider@15" class="mx-auto"></v-skeleton-loader>
            </v-col>
            <v-col>
                <v-data-table v-if="!searchLoading" :headers="Headers" :items="baseData" hide-default-footer disable-pagination disable-sort>
                <!-- 表数据 -->
                <template v-slot:body="">
                    <tbody>
                        <tr v-for="item in baseData" :key="item.id">
                            <td>{{ item.btc }}</td>
                            <td>{{ item.gold }}</td>
                            <td>{{ item.spx }}</td>
                            <td>{{ item.chinaA50 }}</td>
                            <td>{{ item.spxBtc }}</td>
                            <td>{{ item.chinaA50Btc }}</td>
                            <td>{{ item.spxGold }}</td>
                            <td>{{ item.chinaA50Gold }}</td>
                            <td>{{ item.date }}</td>
                            <td>
                                <v-btn icon @click="action('edit',item)">
                                    <v-icon small>fas fa-edit</v-icon>
                                </v-btn>
                                <v-btn icon  @click="action('delete',item)">
                                    <v-icon small>fas fa-trash-alt</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
            <v-divider v-if="!searchLoading"></v-divider>
            <!-- 底部分页 -->
            <template>
                <div class="text-xs-center mt-5" v-if="baseData.length > 0">
                    <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="teal darken-1" circle v-model="pageNo" :length="totalPage" @input="getSyntheticIndices()" :total-visible="15">
                    </v-pagination>
                </div>
            </template>
            </v-col>
        </v-row>
        <!-- 模态框 -->
        <v-dialog v-model="dialog" persistent width="500">
            <v-card v-if="type == 'add'">
                <v-card-title class="fs-24">
                    <span>Add</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close()">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="Date" v-model="addObj.date" hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="BTC" v-model="addObj.btc" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX" v-model="addObj.spx" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="GOLD" v-model="addObj.gold" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50" v-model="addObj.chinaA50" hide-details type="number"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX/BTC" v-model="addObj.spxBtc" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50/Btc" v-model="addObj.chinaA50Btc" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX/Gold" v-model="addObj.spxGold" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50/Gold" v-model="addObj.chinaA50Gold" hide-details type="number"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile color="teal darken-1" text @click="close()">Cancel</v-btn>
                    <v-btn tile color="teal darken-1" text @click="save(addObj)" :loading="addLoading">Submit</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="type == 'add2'">
                <v-card-title class="fs-24">
                    <span>Add Orignal</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close()">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="Date" v-model="addObjParma.date" hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="BTC" v-model="addObjParma.btc" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="GOLD" v-model="addObjParma.gold" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX" v-model="addObjParma.spx" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50" v-model="addObjParma.chinaA50" hide-details type="number"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile color="teal darken-1" text @click="close()">Cancel</v-btn>
                    <v-btn tile color="teal darken-1" text @click="save(addObjParma)" :loading="addLoading">Submit</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="type == 'edit'">
                <v-card-title class="fs-24">
                    <span>Edit</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close()">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="Date" v-model="actionObj.date" hide-details disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="BTC" v-model="actionObj.btc" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="GOLD" v-model="actionObj.gold" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX" v-model="actionObj.spx" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50" v-model="actionObj.chinaA50" hide-details type="number"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX/BTC" v-model="actionObj.spxBtc" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50/BTC" v-model="actionObj.chinaA50Btc" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX/GOLD" v-model="actionObj.spxGold" hide-details type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50/GOLD" v-model="actionObj.chinaA50Gold" hide-details type="number"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile color="teal darken-1" text @click="close()">Cancel</v-btn>
                    <v-btn tile color="teal darken-1" text @click="save(actionObj)" :loading="addLoading">Submit</v-btn>
                </v-card-actions>
            </v-card>
            <v-card  v-if="type == 'delete'">
                <v-card-title class="fs-24">
                    <span>Delete</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="Date" v-model="actionObj.date" hide-details disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="BTC" v-model="actionObj.btc" hide-details disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="GOLD" v-model="actionObj.gold" hide-details disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX" v-model="actionObj.spx" hide-details disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50" v-model="actionObj.chinaA50" hide-details disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX/BTC" v-model="actionObj.spxBtc" hide-details disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50/BTC" v-model="actionObj.chinaA50Btc" hide-details disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="SPX/GOLD" v-model="actionObj.spxGold" hide-details disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field dense class="fs-12" label="China A50/GOLD" v-model="actionObj.chinaA50Gold" hide-details disabled></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile color="teal darken-1" text @click="close()">Cancel</v-btn>
                    <v-btn tile color="teal darken-1" text @click="deleteById(actionObj.id)" :loading="addLoading">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                searchLoading:false,
                addLoading:false,
                baseData:[],
                pageNo:1,
                pageSize:30,
                totalPage:0,
                type:"",
                dialog:false,
                actionObj:{},
                addObj:{
                    btc:0,
                    gold:0,
                    spx:0,
                    chinaA50:0,
                    spxBtc:0,
                    chinaA50Btc:0,
                    spxGold:0,
                    chinaA50Gold:0,
                    date:new Date().toISOString().substr(0, 10),
                },
                addObjParma:{
                    btc:0,
                    gold:0,
                    spx:0,
                    chinaA50:0,
                    date:new Date().toISOString().substr(0, 10),
                },
                Headers:[
                    {text:"BTC"},
                    {text:"GOLD"},
                    {text:"SPX"},
                    {text:"China A50"},
                    {text:"SPX/BTC"},
                    {text:"China A50/BTC"},
                    {text:"SPX/GOLD"},
                    {text:"China A50/GOLD"},
                    {text:"DATE"},
                    {text:"Action"},
                ],
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getSyntheticIndices();
        },
        created() {
           
        },
        methods: {
           changeTable() {
                this.$emit('tablevalue','USER')
            },
            // 分页查询指数信息
            getSyntheticIndices(){
                this.searchLoading = true;
                let api = config.baseUrl + "/synthetic/indices/private/indices?pageNo=" + (this.pageNo - 1 ) + "&pageSize=" + this.pageSize;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.searchLoading = false;
                        this.baseData = data.result.list;
                        this.totalPage =  Math.ceil(data.result.total / this.pageSize);
                    }else{
                        this.searchLoading = false;
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
            },
            action(type,item){
                this.dialog = true;
                this.type = type;
                this.actionObj = JSON.parse(JSON.stringify(item))
            },
            save(item){
                let api = config.baseUrl;
                if(this.type == "add" || this.type == "edit" ){
                    this.addLoading = true;
                    api += "/synthetic/indices/private/saveindices";
                }else if(this.type == "add2"){
                    api += "/synthetic/indices/private/saveindicesByParams";
                }
                console.log(api);
                this.$http.post(api,item).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                        this.getSyntheticIndices();
                        this.close();
                    }else{
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
                
            },
            deleteById(id){
                this.addLoading = true;
                let api = config.baseUrl + "/synthetic/indices/private/delete?id=" + id;
                this.$http.delete(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                        this.getSyntheticIndices();
                    }
                    this.close();
                })
            },
            close(){
                this.dialog = false;
                this.type = "";
                this.searchLoading = false;
                this.addLoading = false;
                this.actionObj = {};
                this.addObj={
                    btc:0,
                    gold:0,
                    spx:0,
                    chinaA50:0,
                    spxBtc:0,
                    chinaA50Btc:0,
                    spxGold:0,
                    chinaA50Gold:0,
                    date:new Date().toISOString().substr(0, 10),
                };
                this.addObjParma={
                    btc:0,
                    gold:0,
                    spx:0,
                    chinaA50:0,
                    date:new Date().toISOString().substr(0, 10),
                }
            }
        }
    }
</script>

<style>

</style>