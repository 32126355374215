<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                    <!-- 绿色框 -->
                    <v-alert :dismissible="false" class="teal white--text" dark border="left" icon="fas fa-dollar-sign">
                        <v-row no-gutters class="align-center">
                            <span>DeFi Funds</span>
                            <v-spacer></v-spacer>
                            <v-btn tile small outlined class="mr-3" @click="table='Indices'">Synthetic Indices</v-btn>
                            <v-btn tile small outlined class="mr-3" @click="table='HOME'">IOB Quasar Fund NAV</v-btn>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12" class="px-5 pt-0" v-if="table=='USER'">
                    <v-row dense v-if="userLoading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@10" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                    <!-- 表头 -->
                    <v-data-table v-if="!userLoading" :headers="UserHeaders" :items="users" hide-default-footer disable-pagination>
                        <!-- 表数据 -->
                        <template v-slot:body="">
                            <tbody>
                                <tr v-for="(user,index) in users" :key="index">
                                    <td>
                                        <v-avatar color="primary" size="40">
                                            <img v-show=" user.avatar  != null" :src=" user.avatar " />
                                            <span v-show=" user.avatar  == null" class="headline white--text">
                                                    {{ user.username.trim().substring(0, 1).toLocaleUpperCase() }}
                                                </span>
                                        </v-avatar>
                                    </td>
                                    <td>{{ user.username }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.role }}</td>
                                    <td>
                                        <v-btn tile icon small color="teal white--text" @click="getNAV(user)">
                                            <v-icon>fas fa-info-circle</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider v-if="!userLoading"></v-divider>
                    <!-- 底部分页 -->
                    <template>
                        <div class="text-xs-center mt-5" v-if="userTotalElements > 0">
                            <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="teal darken-1" circle v-model="userPageNo" :length="userTotalPage" @input="getTradeUsers(userPageNo)" :total-visible="15">
                            </v-pagination>
                        </div>
                    </template>
                </v-col>
                <v-col cols="12" class="pt-0" v-if="table=='NAV'">
                    <v-row class="mx-2">
                        <v-col cols="auto">
                            <v-btn icon small color="teal white--text" @click="table='USER'">
                                <v-icon>fas fa-arrow-left</v-icon>
                            </v-btn>
                            <span class="ml-5">
                                <v-avatar color="primary" size="40">
                                        <img v-show=" navUser.avatar  != null" :src=" navUser.avatar " />
                                        <span v-show=" navUser.avatar  == null" class="headline white--text">
                                                {{ navUser.username.trim().substring(0, 1).toLocaleUpperCase() }}
                                        </span>
                                </v-avatar>
                                <span>
                                     {{ navUser.username }}
                                </span>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mx-2">
                        <v-col cols="3" class="d-flex">
                            <v-btn tile small color="teal mr-3 mt-2 white--text" @click="getAll()">ALL</v-btn>
                            <v-select dense class="fs-12 mr-3" :items="exchanges" label="Exchange" v-model="NavExchange"></v-select>
                            <v-select class="fs-12 mr-3" dense :items="categories" label="Category" v-model="NavCategory"></v-select>
                        </v-col>
                        <v-spacer></v-spacer>                   
                         <v-col cols="auto">
                             <span class="mt-3">
                             <v-btn tile small color="teal white--text" class="ml-5" @click="addNewNav()">New</v-btn>
                      </span>
                         </v-col>
                    </v-row>
                    <v-row dense v-if="tableLoading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                    <v-data-table v-if="!tableLoading" :headers="NAVHeaders" :items="nav" hide-default-footer class="mt-2" dense>
                        <!-- 表数据 -->
                        <template v-slot:body="">
                            <tbody>
                                <!-- <tr v-if="tableLoading">
                                <td colspan="50">
                                    <v-text-field color="teal darken-1" loading disabled height="3"></v-text-field>
                                </td>
                                </tr> -->
                                <tr v-for="(n,index) in nav" :key="index">
                                    <td>{{ n.exchange }}</td>
                                    <td>{{ n.tradingCategory }}</td>
                                    <td :title="n.USD.toFixed(8)">{{ n.USD }}</td>
                                    <td :title="n.USDT.toFixed(8)">{{ n.USDT }}</td>
                                    <td :title="n.BTC.toFixed(8)">{{ n.BTC }}</td>
                                    <td :title="n.ETH.toFixed(8)">{{ n.ETH }}</td>
                                    <td :title="n.BCH.toFixed(8)">{{ n.BCH }}</td>
                                    <td :title="n.LTC.toFixed(8)">{{ n.LTC }}</td>
                                    <td :title="n.XRP.toFixed(8)">{{ n.XRP}}</td>
                                    <td :title="n.EOS.toFixed(8)">{{ n.EOS }}</td>
                                    <td :title="n.ETC.toFixed(8)">{{ n.ETC }}</td>
                                    <td :title="n.BSV.toFixed(8)">{{ n.BSV }}</td>
                                    <td :title="n.TRX.toFixed(8)">{{ n.TRX }}</td>
                                    <td :title="n.NEO.toFixed(8)">{{ n.NEO }}</td>
                                    <td :title="n.LINK.toFixed(8)">{{ n.LINK }}</td>
                                    <td :title="n.DASH.toFixed(8)">{{ n.DASH }}</td>
                                    <td :title="n.QTUM.toFixed(8)">{{ n.QTUM }}</td>
                                    <td :title="n.ATOM.toFixed(8)">{{ n.ATOM }}</td>
                                    <td :title="n.XTZ.toFixed(8)">{{ n.XTZ }}</td>
                                    <td :title="n.ADA.toFixed(8)">{{ n.ADA }}</td>
                                    <td :title="n.XLM.toFixed(8)">{{ n.XLM }}</td>
                                    <td :title="n.ONT.toFixed(8)">{{ n.ONT }}</td>
                                    <td :title="n.ZEC.toFixed(8)">{{ n.ZEC }}</td>
                                    <td :title="n.IOST.toFixed(8)">{{ n.IOST }}</td>
                                    <td :title="n.ALGO.toFixed(8)">{{ n.ALGO }}</td>
                                    <td :title="n.DOT.toFixed(8)">{{ n.DOT }}</td>
                                    <td :title="n.XMR.toFixed(8)">{{ n.XMR }}</td>
                                    <td :title="n.UNI.toFixed(8)">{{ n.UNI }}</td>
                                    <td class="px-0 mx-0">{{ dateFormat(n.timestamp) }}</td>
                                    <td>
                                        <v-btn icon @click="edit(n)" small>
                                            <v-icon small>
                                               fas fa-edit
                                           </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider v-if="!tableLoading"></v-divider>
                    <template>
                        <div class="text-center mt-5" v-if="noContent" >No data available</div>
                        <div class="text-center mt-5" v-if="navTotalElements > 0">
                            <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="teal darken-1" circle v-model="navPageNo" :length="navTotalPage" @input="getNAV(navUser)" :total-visible="30">
                            </v-pagination>
                        </div>
                    </template>
                </v-col>
                <!-- 首页NAV -->
                <v-col cols="12" v-if="table=='HOME'">
                    <v-row class="mx-2">
                        <v-col cols="auto">
                            <v-btn color="teal white--text" icon small @click="table='USER'">
                                <v-icon>fas fa-arrow-left</v-icon>
                            </v-btn>
                            <span class="ml-5">IOB Quasar Fund NAV</span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-btn class="ml-3" color="teal white--text" icon small @click="isShowCalcAdd = !isShowCalcAdd">
                                <v-icon v-if="!isShowCalcAdd">fas fa-calculator</v-icon>
                                <v-icon v-if="isShowCalcAdd">fas fa-table</v-icon>
                            </v-btn>
                            <v-btn class="ml-3" color="teal white--text" icon small @click="addNewHomeNav()">
                                <v-icon>fas fa-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mx-2" v-if="isShowCalcAdd">
                        <v-col cols="4" class="d-flex mt-5">
                            <v-text-field label="date" class="my-0 py-0" hide-details v-model="navDate"></v-text-field>
                            <v-btn tile color="teal white--text" small class="ml-5" @click="saveHomeNav()">Add</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mx-2" v-if="!isShowCalcAdd">
                        <v-col cols="12" v-if="homeLoading">
                            <v-skeleton-loader dense type="table-row-divider@15" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                        <v-col>
                            <v-data-table v-if="!homeLoading" :headers="HomeHeaders" :items="homes" hide-default-footer disable-pagination>
                            <!-- 表数据 -->
                            <template v-slot:body="">
                                <tbody>
                                    <tr v-for="(item,index) in homes" :key="index">
                                        <td class="text-right">{{ (homePageNo -1) * pageSize + index +1}}</td>
                                        <td class="text-right">{{item.quasarNavInUsd}}</td>
                                        <td class="text-right">{{item.quasarNavInBTC}}</td>
                                        <td class="text-right">{{item.btcPrice}}</td>
                                        <td class="text-right">{{item.ethBtc}}</td>
                                        <td>{{item.date}}</td>
                                        <td>
                                            <v-btn icon @click="editHome(item)">
                                                <v-icon small>fas fa-edit</v-icon>
                                            </v-btn>
                                            <v-btn icon  @click="deleteHome(item)">
                                                <v-icon small>fas fa-trash-alt</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                        <v-divider v-if="!homeLoading"></v-divider>
                        <!-- 底部分页 -->
                        <template>
                            <div class="text-xs-center mt-5" v-if="homes.length > 0">
                                <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="teal darken-1" circle v-model="homePageNo" :length="homeTotalPage" @input="getHomeNav(homePageNo)" :total-visible="15">
                                </v-pagination>
                            </div>
                        </template>
                        </v-col>
                        
                    </v-row>
                </v-col>
                <v-col cols="12" v-if="table=='Indices'">
                    <synthetic-indices @tablevalue="changeTable"></synthetic-indices>
                </v-col>
            </v-row>
            <!-- 弹出框-->
            <!-- 用户nav界面 -->
            <v-dialog v-model="dialog" persistent width="500">
                <v-card v-if="state == 'edit'">
                    <v-card-title class="fs-24">
                        <span>Edit</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog = false">
                            <v-icon>fas fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                               <v-col cols="12" sm="12" md="12">
                                    <span>{{dateFormat(editNAV.timestamp)}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field dense class="fs-12" label="Exchange" v-model="editNAV.exchange" disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field dense class="fs-12" label="Category" v-model="editNAV.tradingCategory" disabled></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="USD" v-model="editNAV.USD"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="USDT" v-model="editNAV.USDT"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="BTC" v-model="editNAV.BTC"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ETH" v-model="editNAV.ETH"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="BCH" v-model="editNAV.BCH"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="LTC" v-model="editNAV.LTC"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="XRP" v-model="editNAV.XRP"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="EOS" v-model="editNAV.EOS"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ETC" v-model="editNAV.ETC"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="BSV" v-model="editNAV.BSV"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="TRX" v-model="editNAV.TRX"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="NEO" v-model="editNAV.NEO"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="LINK" v-model="editNAV.LINK"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="DASH" v-model="editNAV.DASH"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="QTUM" v-model="editNAV.QTUM"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ATOM" v-model="editNAV.ATOM"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="XTZ" v-model="editNAV.XTZ"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ADA" v-model="editNAV.ADA"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="XLM" v-model="editNAV.XLM"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ONT" v-model="editNAV.ONT"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ZEC" v-model="editNAV.ZEC"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="IOST" v-model="editNAV.IOST"></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ALGO" v-model="editNAV.ALGO"></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="DOT" v-model="editNAV.DOT"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="XMR" v-model="editNAV.XMR"></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="UNI" v-model="editNAV.UNI"></v-text-field>
                                </v-col>
                            </v-row>
                            </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile color="teal darken-1" text @click="dialog = false">Cancel</v-btn>
                        <v-btn tile color="teal darken-1" text @click="save(editNAV)" :loading="homeAddLoading">Submit</v-btn>
                    </v-card-actions>
                </v-card>
                <!-- 新增用户nav -->
                <v-card v-if="state == 'add'">
                    <v-card-title class="fs-24">
                        <span>New</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog = false">
                            <v-icon>fas fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="3" sm="3" md="3">
                                    <v-select class="fs-12" dense :items="exchanges" label="Exchange" v-model="addNAV.exchange"></v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select class="fs-12" dense :items="categories" label="Category" v-model="addNAV.tradingCategory"></v-select>
                                </v-col>
                                <v-col cols="5" sm="5" md="5">
                                    <v-text-field class="fs-12" dense label="Time (UTC)" v-model="addNAV.timestamp" ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="USD" v-model="addNAV.USD"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="USDT" v-model="addNAV.USDT"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="BTC" v-model="addNAV.BTC"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="ETH" v-model="addNAV.ETH"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="BCH" v-model="addNAV.BCH"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="LTC" v-model="addNAV.LTC"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="XRP" v-model="addNAV.XRP"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="EOS" v-model="addNAV.EOS"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="ETC" v-model="addNAV.ETC"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="BSV" v-model="addNAV.BSV"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="TRX" v-model="addNAV.TRX"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field class="fs-12" dense label="NEO" v-model="addNAV.NEO"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="LINK" v-model="addNAV.LINK"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="DASH" v-model="addNAV.DASH"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="QTUM" v-model="addNAV.QTUM"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ATOM" v-model="addNAV.ATOM"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="XTZ" v-model="addNAV.XTZ"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ADA" v-model="addNAV.ADA"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="XLM" v-model="addNAV.XLM"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ONT" v-model="addNAV.ONT"></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ZEC" v-model="addNAV.ZEC"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="IOST" v-model="addNAV.IOST"></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="ALGO" v-model="addNAV.ALGO"></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="DOT" v-model="addNAV.DOT"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="XMR" v-model="addNAV.XMR"></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" class="py-0 my-0">
                                    <v-text-field dense class="fs-12" label="UNI" v-model="addNAV.UNI"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile color="teal darken-1" text @click="dialog = false">Cancel</v-btn>
                        <v-btn tile color="teal darken-1" text @click="save(addNAV)" :loading="homeAddLoading">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- 首页NAV弹窗 -->
            <v-dialog v-model="homeDialog" persistent width="600">
                <!-- 首页的编辑弹窗 -->
                <v-card v-if=" homeState == 'edit'">
                    <v-card-title class="fs-24">
                        <span>Edit</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="homeDialog = false">
                            <v-icon>fas fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4" sm="4" md="4">
                                <v-text-field label="Quasar Nav In USD" v-model="homeEdit.quasarNavInUsd"></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4">
                                <v-text-field label="Quasar Nav In BTC" v-model="homeEdit.quasarNavInBTC"></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4">
                                <v-text-field label="BTC Price" v-model="homeEdit.btcPrice"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" sm="4" md="4">
                                <v-text-field label="ETH BTC" v-model="homeEdit.ethBtc"></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4">
                                <v-text-field label="Date" v-model="homeEdit.date"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile color="teal darken-1" text @click="homeDialog = false">Cancel</v-btn>
                        <v-btn tile color="teal darken-1" text @click="saveHome(homeEdit)" :loading="homeAddLoading">OK</v-btn>
                    </v-card-actions>
                </v-card>
                <!-- 首页的删除弹窗 -->
                <v-card v-if=" homeState == 'delete'">
                    <v-card-title class="fs-24">
                        <span>Are you sure you want to delete it?</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="homeDialog = false">
                            <v-icon>fas fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="black--text">
                        <v-divider class="mb-5"></v-divider>
                        <v-row>
                            <v-col cols="6" class="my-0 py-0">
                                <span>{{"Date :" + deteHomeobj.date}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <span>{{ "Quasar Nav In USD :" + deteHomeobj.quasarNavInUsd}}</span>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <span>{{ "Quasar Nav In BTC :" + deteHomeobj.quasarNavInBTC}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <span>{{ "BTC Price :" + deteHomeobj.btcPrice}}</span>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <span>{{ "ETH BTC :" + deteHomeobj.ethBtc}}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text tile color="teal darken-1" @click="homeDialog = false">Cancel</v-btn>
                        <v-btn tile color="red darken-1" text @click="deleteHomeById(deteHomeobj.id)" :loading="homeAddLoading">Delete</v-btn>
                    </v-card-actions>
                </v-card>
                <!-- 首页的新增弹窗 -->
                <v-card v-if=" homeState == 'add'">
                    <v-card-title class="fs-24">
                        <span>New</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="homeDialog = false">
                            <v-icon>fas fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="black--text">
                        <v-row>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <v-text-field label="Quasar Nav In USD" v-model="addHomeNav.quasarNavInUsd"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <v-text-field label="Quasar Nav In BTC" v-model="addHomeNav.quasarNavInBTC"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <v-text-field label="BTC Price" v-model="addHomeNav.btcPrice"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <v-text-field label="ETH BTC" v-model="addHomeNav.ethBtc"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6" class="my-0 py-0">
                                <v-text-field label="Date" v-model="addHomeNav.date"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                         <v-btn tile text color="teal darken-1" @click="homeDialog = false">Cancel</v-btn>
                        <v-btn tile color="teal darken-1" text @click="saveHome(addHomeNav)" :loading="homeAddLoading">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    import Data from "../signal/Data";
    import SyntheticIndices from "../../components/SyntheticIndices"
    export default {
        data() {
            return {
                navDate:this.getUtcDate(),
                state:'edit',
                table:'USER',
                userLoading:true,
                tableLoading:true,
                isUser:true,
                users:[],
                nav:[],
                navUser:{},
                editNAV:{},
                addNAV:{
                    id: null,
                    userId: null,
                    exchange: "okex",
                    tradingCategory: "funding",
                    USD: 0,
                    USDT: 0,
                    BTC: 0,
                    ETH: 0,
                    BCH: 0,
                    LTC: 0,
                    XRP: 0,
                    EOS: 0,
                    ETC: 0,
                    BSV: 0,
                    TRX: 0,
                    NEO: 0,
                    LINK: 0,
                    DASH: 0,
                    QTUM: 0,
                    ATOM: 0,
                    XTZ: 0,
                    ADA: 0,
                    XLM: 0,
                    ONT: 0,
                    ZEC: 0,
                    IOST: 0,
                    ALGO: 0,
                    DOT: 0,
                    XMR: 0,
                    UNI: 0,
                    timestamp: this.getUtcDate() + " 00:00",
                },
                addHomeNav:{
                    id:null,
                    quasarNavInUsd: 0,
                    quasarNavInBTC: 0,
                    btcPrice: 0,
                    ethBtc: 0,
                    date: this.getUtcDate(),
                },
                userPageNo:1,
                userTotalElements:0,
                userTotalPage:0,
                navPageNo:1,
                navTotalElements:0,
                navTotalPage:0,
                pageSize:20,
                noContent:false,
                dialog:false,
                NavExchange:null,
                NavCategory:null,
                exchanges:["okex","kraken"],
                categories:["funding","cash","swap"],
                UserHeaders: [
                    {
                        text: 'Avatar',
                        value: 'avatar',
                        sortable: false,
                    },
                    {
                        text: 'User Name',
                        value: 'username',
                        sortable: false,
                    },
                    {
                        text: 'Email',
                        value: 'email',
                        sortable: false,
                    },
                    {
                        text: 'Role',
                        value: 'role',
                        sortable: false,
                    },
                    {
                        text: 'Action',
                        value: 'action',
                        sortable: false,
                    },
                ],
                NAVHeaders: [
                    {
                        text: 'Exchange',
                        value: 'exchange',
                        sortable: false,
                        width:'5%'
                    },
                    {
                        text: 'Category',
                        value: 'tradingCategory',
                        sortable: false,
                        width:'5%'
                    },
                    {
                        text: 'USD',
                        value: 'USD',
                        sortable: false,
                    },
                    {
                        text: 'USDT',
                        value: 'USDT',
                        sortable: false,
                    },
                    {
                        text: 'BTC',
                        value: 'BTC',
                        sortable: false,
                    },
                    {
                        text: 'ETH',
                        value: 'ETH',
                        sortable: false,
                    },{
                        text: 'BCH',
                        value: 'BCH',
                        sortable: false,
                    },{
                        text: 'LTC',
                        value: 'LTC',
                        sortable: false,
                    },{
                        text: 'XRP',
                        value: 'XRP',
                        sortable: false,
                    },{
                        text: 'EOS',
                        value: 'EOS',
                        sortable: false,
                    },{
                        text: 'ETC',
                        value: 'ETC',
                        sortable: false,
                    },{
                        text: 'BSV',
                        value: 'BSV',
                        sortable: false,
                    },{
                        text: 'TRX',
                        value: 'TRX',
                        sortable: false,
                    },{
                        text: 'NEO',
                        value: 'NEO',
                        sortable: false,
                    },{
                        text: 'LINK',
                        value: 'LINK',
                        sortable: false,
                    },{
                        text: 'DASH',
                        value: 'DASH',
                        sortable: false,
                    },{
                        text: 'QTUM',
                        value: 'QTUM',
                        sortable: false,
                    },{
                        text: 'ATOM',
                        value: 'ATOM',
                        sortable: false,
                    },{
                        text: 'XTZ',
                        value: 'XTZ',
                        sortable: false,
                    },{
                        text: 'ADA',
                        value: 'ADA',
                        sortable: false,
                    },{
                        text: 'XLM',
                        value: 'XLM',
                        sortable: false,
                    },{
                        text: 'ONT',
                        value: 'ONT',
                        sortable: false,
                    },{
                        text: 'ZEC',
                        value: 'ZEC',
                        sortable: false,
                    },{
                        text: 'IOST',
                        value: 'IOST',
                        sortable: false,
                    },{
                        text: 'ALGO',
                        value: 'ALGO',
                        sortable: false,
                    },{
                        text: 'DOT',
                        value: 'DOT',
                        sortable: false,
                    },{
                        text: 'XMR',
                        value: 'XMR',
                        sortable: false,
                    },{
                        text: 'UNI',
                        value: 'UNI',
                        sortable: false,
                    },{
                        text: 'Time (UTC)',
                        value: 'timestamp',
                        sortable: false,
                        width:'120px'
                    },{
                        text: 'Action',
                        value: 'action',
                        sortable: false,
                    },
                ],
                HomeHeaders:[
                    {text:"No",sortable:false,align:'right'},
                    {text:"Quasar NAV In USD",sortable:false,align:'right'},
                    {text:"Quasar NAV In BTC",sortable:false,align:'right'},
                    {text:"BTC Price",sortable:false,align:'right'},
                    {text:"ETH:BTC",sortable:false,align:'right'},
                    {text:"Date",sortable:false},
                    {text:"Action",sortable:false},
                ],
                homes:[],
                homePageNo:1,
                homeTotalElements:0,
                homeTotalPage:0,
                homeLoading:false,
                homeDialog:false,
                homeState:"edit",
                homeEdit:{},
                deteHomeId:null,
                homeAddLoading:false,
                isShowCalcAdd: false
            }
        },
        components:{
            SyntheticIndices,
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            table(val){
                this.navPageNo = 1;
                if(val == "HOME"){
                    this.homePageNo = 1;
                    this.getHomeNav(this.homePageNo);
                }
            },
            NavExchange(v){
                if (v != null){
                    this.getNAV(this.navUser)
                }
            },
            NavCategory(v){
                if (v != null){
                    this.getNAV(this.navUser)
                }
            }
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getTradeUsers();
        },
        created() {
            
        },
        methods: {
            // 分页查询首页的NAV
            getHomeNav(pageNo){
                this.homes = [];
                this.homeTotalElements = 0;
                this.homeTotalPage = 0;
                this.table = 'HOME';
                this.homeLoading = true;
                let api = config.baseUrl + "/admin/private/getHomeNav?pageNo="+this.homePageNo + "&pageSize="+this.pageSize;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.homes = data.result.quasarNAVList;
                        this.homeTotalElements = data.result.total;
                        this.homeTotalPage = Math.ceil(data.result.total / this.pageSize);
                        this.homeLoading =false;
                    }else{
                        this.homeLoading =false;
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
            },
           // 分页查询交易用户
            getTradeUsers(pageNo){
                this.users = [];
                this.userTotalElements = 0;
                this.userTotalPage = 0;
                this.table = 'USER';
                this.userLoading = true;
                let api = config.baseUrl + "/asset/private/getTradeUsers?pageNo="+this.userPageNo + "&pageSize="+this.pageSize;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if (data.code == 200){
                        this.users = data.result.userList;
                        this.userTotalElements = data.result.count;
                        this.userTotalPage = Math.ceil(data.result.count / this.pageSize);
                        this.userLoading =false;
                    }else {
                        this.userLoading =false;
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
            },
            // 获取用户NAV
            getNAV(user){
                this.noContent = false;
                this.nav = [];
                this.navTotalElements = 0;
                this.navTotalPage = 0;
                this.tableLoading = true;
                this.navUser = user
                this.table = 'NAV';
                let api = config.baseUrl + "/asset/private/getUserNAV?userId="+user.id+"&pageNo="+this.navPageNo+"&pageSize=20&exchange="+this.NavExchange+"&category="+this.NavCategory;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if (data.code == 200){
                        this.nav = data.result.netAssetValueList;
                        this.navTotalElements = data.result.count;
                        this.navTotalPage = Math.ceil(data.result.count / this.pageSize);
                        this.tableLoading = false;
                    }else {
                        this.noContent = true;
                        this.tableLoading = false;
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
            },
            getAll(){
                this.NavExchange = null;
                this.NavCategory = null;
                this.getNAV(this.navUser);
            },
            // 编辑
            edit(nav){
                this.state = 'edit';
                this.dialog = true;
                this.editNAV = JSON.parse(JSON.stringify(nav));
                this.editNAV.USD = nav.USD.toFixed(8);
                this.editNAV.USDT = nav.USDT.toFixed(8);
                this.editNAV.BTC = nav.BTC.toFixed(8);
                this.editNAV.ETH = nav.ETH.toFixed(8);
                this.editNAV.BCH = nav.BCH.toFixed(8);
                this.editNAV.LTC = nav.LTC.toFixed(8);
                this.editNAV.XRP = nav.XRP.toFixed(8);
                this.editNAV.EOS = nav.EOS.toFixed(8);
                this.editNAV.ETC = nav.ETC.toFixed(8);
                this.editNAV.BSV = nav.BSV.toFixed(8);
                this.editNAV.TRX = nav.TRX.toFixed(8);
                this.editNAV.NEO = nav.NEO.toFixed(8);
                this.editNAV.LINK = nav.LINK.toFixed(8);
                this.editNAV.DASH = nav.DASH.toFixed(8);
                this.editNAV.QTUM = nav.QTUM.toFixed(8);
                this.editNAV.ATOM = nav.ATOM.toFixed(8);
                this.editNAV.XTZ = nav.XTZ.toFixed(8);
                this.editNAV.ADA = nav.ADA.toFixed(8);
                this.editNAV.XLM = nav.XLM.toFixed(8);
                this.editNAV.ONT = nav.ONT.toFixed(8);
                this.editNAV.ZEC = nav.ZEC.toFixed(8);
                this.editNAV.IOST = nav.IOST.toFixed(8);
                this.editNAV.ALGO = nav.ALGO.toFixed(8);
                this.editNAV.DOT = nav.DOT.toFixed(8);
                this.editNAV.XMR = nav.XMR.toFixed(8);
                this.editNAV.UNI = nav.UNI.toFixed(8);
            },
            // 编辑主页nav按钮操作
            editHome(item){
                this.homeDialog = true;
                this.homeState = "edit";
                this.homeEdit = JSON.parse(JSON.stringify(item));
            },
            // 删除主页nav按钮操作
            deleteHome(item){
                this.homeDialog = true;
                this.homeState = "delete";
                this.deteHomeobj = item;
            },
            // 根据主键删除主页nav
            deleteHomeById(id){
                this.homeAddLoading = true;
                let api = config.baseUrl + "/admin/private/deleteNav?id="+id;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.homeAddLoading = false;
                        this.homePageNo = 1;
                        this.getHomeNav(1);
                        this.homeDialog = false;
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }else{
                        this.homeAddLoading = false;
                        this.homeDialog = false;
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
            },
            // 新增用户nav
            addNewNav(){
                this.state = 'add';
                this.dialog = true;
                this.addNAV.userId = this.navUser.id;
            },
            // 新增首页nav
            addNewHomeNav(){
                this.homeState = 'add';
                this.homeDialog = true;
            },
            // 保存用户nav
            save(nav){
                this.homeAddLoading = true;
                if (this.state == 'add'){
                    nav.timestamp = this.parseTime(nav.timestamp);
                }
                let api = config.baseUrl + "/asset/admin/private/saveNav";
                this.$http.post(api,nav).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.homeAddLoading = false;
                        this.dialog = false;
                        this.getNAV(this.navUser);
                        if (this.state == 'add'){
                            this.addNAV.timestamp= this.getUtcDate() + " 00:00";
                        }
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }else {
                        this.homeAddLoading = false;
                        this.dialog = false;
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
            },
            // 保存首页nav
            saveHome(home){
                this.homeAddLoading = true;
                let api = config.baseUrl + "/admin/private/saveHomeNav";
                this.$http.post(api,home).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.homeAddLoading = false;
                        this.homeDialog = false;
                        this.homePageNo = 1;
                        this.getHomeNav(1);
                        if (this.homeState == 'add'){
                            this.addHomeNav.date= this.getUtcDate();
                        }
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }else {
                        this.homeAddLoading = false;
                        this.homeDialog = false;
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
            },
            // 根据日期保存首页nav
            saveHomeNav(){
                if(this.navDate !=null){
                    let api = config.baseUrl + "/admin/private/saveQuasarNav?date="+this.navDate;
                    this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.homePageNo = 1;
                        this.getHomeNav(1);
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }else {
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                    }
                })
                }
            },
            // 时间戳格式化为utc时间
            dateFormat(timestamp){
                // 在日期格式中，月份是从0开始的，因此要加0
                // 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11
                var date = new Date(timestamp);
                var year = date.getUTCFullYear();
                var month = date.getUTCMonth() + 1;
                month = month < 10 ? ('0' + month) : month;
                var day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
                var hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
                var minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
                return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
            },
            // 将时间转为utc时间戳
            parseTime(time){
                time = time+":00.000Z'"
                var date = new Date(time);
                return date.getTime();
            },
            // 获取今天的utc日期
            getUtcDate(){
                var date = new Date();
                var seperator1 = "-";
                var year = date.getUTCFullYear();
                var month = date.getUTCMonth() + 1;
                var strDate = date.getUTCDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = year + seperator1 + month + seperator1 + strDate;
                return currentdate;
            },
            // 修改table的值
            changeTable(data){
                this.table = data;
            },
        }
    }
</script>

<style>

</style>
